import * as React from 'react';
import List from '@mui/material/List';
import Category from '../components/Category';
import Loading from '../../menu/components/Loading';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import CategoryForm from '../components/CategoryForm';
import useToken from '../../assest/UseToken';
export default function Categories() {
    const [categories, setCategories] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [addCategory, setAddCategory] = React.useState({ open: false, categoryInfo: new FormData(), yesOrNo: false })
    const token = useToken().authorisation?.token;
    React.useEffect(() => {
        getData();
    }, []);
    async function getData() {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/categories`);
        let categories = await response.json();
        setCategories(categories.categories)
        setIsLoading(false)
    }
    React.useEffect(() => {
        if (addCategory.yesOrNo == true) {
            fetch(`${process.env.REACT_APP_URL}/api/categories/addCategory`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: addCategory.categoryInfo,
            })
                .then((response) => {
                    if (response.status) {
                        return response.json();
                    }
                    throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                    if (responseJson.hasOwnProperty('name')) {
                        getData();
                    }
                    return responseJson;
                })
                .catch((error) => {
                    console.log(error);
                });
            addCategory.yesOrNo = false
        }
    }, [addCategory.yesOrNo]);
    return (
        <div>
            <div>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <List sx={{ width: '98%', bgcolor: 'background.paper' }}>
                        <CategoryForm open={addCategory.open} setAddCategory={setAddCategory} categoryInfo={addCategory.categoryInfo} addCategory={addCategory} yesOrNo={addCategory.yesOrNo}  />
                        <Fab size="medium" color="primary" aria-label="add" onClick={(e) => setAddCategory({ ...addCategory, open: true })}>
                            <AddIcon />
                        </Fab>
                        {categories.map(
                            ({ _id, name, title, items, icon }) => (
                                <Category
                                    icon={icon}
                                    title={title}
                                    name={name}
                                    key={_id}
                                    setCategories={setCategories}
                                    categories={categories}
                                    id={_id}
                                />
                            )
                        )}
                    </List>
                )
                }
            </div >

        </div>
    );
}