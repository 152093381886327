import './App.css';
import Items from './menu/pages/Items';
import Categories from './menu/pages/Categories';
import Login from './dashboard/pages/Login';
import Dashboard from './dashboard/pages/Dashboard';
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<><Categories /><Items /></>} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>

    </div>

  );
}

export default App;
