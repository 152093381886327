import * as React from 'react';

export default function CategoryCard(props) {
    const icon = `${process.env.REACT_APP_URL}/${props.icon}`
    const handleClickScroll = () => {
        const element = document.getElementById(props.id);
        console.log(props.id)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="card" onClick={handleClickScroll}>
            <img src={icon} className="card__image" alt={props.name} />
            <div className="card__overlay">
                <div className="card__header">
                    <div className="card__header-text">
                        <h3 className="card__title">{props.name}</h3>
                    </div>
                </div>
                <p className="card__description">{props.title}</p>
            </div>
        </div>
    );
}
