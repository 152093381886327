import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import AlertDialog from './Dialog';
import AlertMes from '../../menu/components/Alert';
import useToken from '../../assest/UseToken';
import ItemForm from './ItemForm';
export default function Item(props) {
    const image = `${process.env.REACT_APP_URL}/${props.image}`
    const [dialog, setDialog] = React.useState({ open: false, text: '', yesOrNo: false })
    const [addItem, setAddItem] = React.useState({ open: false, itemInfo: new FormData(), yesOrNo: false, yesOrNoUpdate: false })
    const [alert, setAlert] = React.useState({ message: '', severity: '', open: false });
    const token = useToken().authorisation?.token;
    const fetchData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/api/items/${props.id}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!response) {
            throw new Error("Data coud not be fetched!");
        } else {
            return response.json();
        }
    };
    React.useEffect(() => {
        if (dialog.yesOrNo === true) {
            fetchData()
                .then((res) => {
                    if (res.hasOwnProperty('message')) {
                        var items = props.items.filter(x => {
                            return x._id != props.id;
                        })
                        props.setItems(items)
                        setAlert({ ...alert, message: res.message, open: true, severity: 'success' })
                    }
                })
                .catch((e) => {
                    console.log(e.message);
                });
        }
    }, [dialog.yesOrNo])

    React.useEffect(() => {
        if (addItem.yesOrNoUpdate == true) {
            fetch(`${process.env.REACT_APP_URL}/api/items/${props.id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: addItem.itemInfo,
            })
                .then((response) => {
                    if (response.status) {
                        return response.json();
                    }
                    throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                    if (responseJson.hasOwnProperty('name')) {
                        const newItem = props.items.map(obj => {
                            if (obj._id === props.id) {
                                return { ...obj, ...responseJson };
                            }
                            return obj;
                        });
                        props.setItems(newItem)
                    }
                    return responseJson;
                })
                .catch((error) => {
                    console.log(error);
                });
            addItem.yesOrNoUpdate = false
        }
    }, [addItem.yesOrNoUpdate]);
    return (

        <div>
            <AlertDialog open={dialog.open} text={dialog.text} setOpen={setDialog} />
            <AlertMes open={alert.open} severity={alert.severity} message={alert.message} setOpen={setAlert} />
            <ItemForm open={addItem.open} setAddItem={setAddItem} itemInfo={addItem.itemInfo} addItem={addItem} yesOrNo={addItem.yesOrNo} categories={props.categories} dialogTitle={'Edit Item'} dialogContentText={'To Edit item to this website, please enter a new item name, description, price and upload a new image'} itemData={{ name: props.name, description: props.description, price: props.price, image: props.image, categoryName: props.categoryName, id: props.id, category_id: props.category_id }} action={'Edit'} yesOrNoUpdate={addItem.yesOrNoUpdate} />
            <ListItem
                secondaryAction={
                    <div className='actions'>
                        <IconButton edge="end" aria-label="delete" onClick={(e) => { setDialog({ ...dialog, open: true, text: 'You are trying delete an item! Are you sure?' }) }}>
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={(e) => setAddItem({ ...addItem, open: true })}>
                            <EditIcon sx={{ color: 'blue' }} />
                        </IconButton>
                    </div>
                }>
                <ListItemAvatar>
                    <Avatar src={image}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.name} secondary={props.description} />

            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    );
}