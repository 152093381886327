
import * as React from "react"
import Alert from "../../menu/components/Alert";
import useToken from "../../assest/UseToken";
import { useNavigate } from "react-router-dom";


export default function Login() {

    const [credential, setCredential] = React.useState({ email: '', password: '' });
    const [alert, setAlert] = React.useState({ message: '', severity: '', open: false });
    const { setToken } = useToken();
    let navigate = useNavigate();
    const fetchData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/api/users/login`,
            {
                method: "POST",
                body: new URLSearchParams(credential),
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                    Accept: "*/*",
                },
            }
        );
        if (!response) {
            throw new Error("Data coud not be fetched!");
        } else {
            return response.json();
        }
    };


    const Login = (e) => {
        e.preventDefault();
        fetchData()
            .then((res) => {
                setToken(res)
                navigate("/dashboard");
            })
            .catch((e) => {
                setAlert({ ...alert, message: 'Invalid Email or Passowrd!', open: true, severity: 'error' })
            });

    };

    return (
        <div className="login">
            <Alert open={alert.open} severity={alert.severity} message={alert.message} setOpen={setAlert} />
            <form onSubmit={Login}>
                <h3>Login Here</h3>
                <input onChange={(e) => setCredential({ ...credential, email: e.target.value })} type="text" placeholder="Email" id="username" />
                <input onChange={(e) => setCredential({ ...credential, password: e.target.value })} type="password" placeholder="Password" id="password" />
                <button >Log In</button>
            </form>
        </div>
    )
}   