import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AlertMes from '../../menu/components/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export default function ItemForm(props) {
    const [oldImage, setOldImage] = React.useState(`${process.env.REACT_APP_URL}/${props.itemData?.image}`)
    const [itemData, setItemData] = React.useState({ name: props.itemData?.name || '', description: props.itemData?.description || '', image: props.itemData?.image || null, price: props.itemData?.price || '', category_id: props.itemData?.category_id || '' })
    const [alert, setAlert] = React.useState({ message: '', severity: '', open: false });
    const [image, setImage] = React.useState();
    const handleClickAdd = async () => {
        const itemForm = new FormData()
        itemForm.append('name', itemData.name)
        itemForm.append('description', itemData.description)
        itemForm.append('image', itemData.image)
        itemForm.append('price', itemData.price)
        itemForm.append('category_id', itemData.category_id)
        if (document.getElementById("Add")) {

            if (itemData.name.length === 0 || itemData.description.length === 0 || itemData.image === null || itemData.category_id.length === 0 || itemData.price.length === 0) {
                return setAlert({ ...alert, message: 'please fill all felds!', open: true, severity: 'error' })
            }
            props.setAddItem({ ...props.addItem, open: false, itemInfo: itemForm, yesOrNo: true });
            setItemData({ name: '', description: '', image: null, price: '', category_id: '' })
            setImage(null)
        }
        if (document.getElementById("Edit")) {
            props.setAddItem({ ...props.addItem, open: false, itemInfo: itemForm, yesOrNoUpdate: true });
            setItemData({ name: '', description: '', image: null, price: '', category_id: '' })
            setImage(null)
        }


    };

    const handleClose = () => {
        props.setAddItem({ ...props.addItem, open: false });
    };


    function handleChange(e) {
        setImage(URL.createObjectURL(e.target.files[0]));
        setOldImage(URL.createObjectURL(e.target.files[0]))
        setItemData({ ...itemData, image: e.target.files[0] })
    }

    const handleChangeCategory = (event) => {
        setItemData({ ...itemData, category_id: event.target.value })
    };

    return (
        <div>

            <Dialog open={props.open} onClose={handleClose}>
                <AlertMes open={alert.open} severity={alert.severity} message={alert.message} setOpen={setAlert} />
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.dialogContentText}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Item Name"
                        placeholder={props.itemData?.name || ""}
                        type="text"
                        fullWidth
                        onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="title"
                        multiline
                        label="Item Description"
                        placeholder={props.itemData?.description || ""}
                        type="text"
                        fullWidth
                        onChange={(e) => setItemData({ ...itemData, description: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="price"
                        label="Item Price"
                        type="number"
                        fullWidth
                        onChange={(e) => setItemData({ ...itemData, price: e.target.value })}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Select category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Category Name"
                            onChange={handleChangeCategory}
                        >
                            {props.categories.map(
                                ({ _id, name, title, items, icon }) => (
                                    <MenuItem
                                        value={_id}
                                        key={_id}
                                    >
                                        {name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                            alt="image"
                            src={oldImage || image}
                            sx={{ width: 56, height: 56 }}
                        />
                        <div>Uplode Image</div>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <input type="file" accept="image/*" onChange={handleChange} className="hidden" />
                            <PhotoCamera />
                        </IconButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button id={props.action} onClick={handleClickAdd}>{props.action}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}