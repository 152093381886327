import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";

export default function ItemCard(props) {
  const image = `${process.env.REACT_APP_URL}/${props.image}`;
  const icon = `${process.env.REACT_APP_URL}/${props.icon}`;
  return (
    <Card
      sx={{
        maxWidth: 345,
        minWidth: 345,
        maxHeight: 400,
        minHeight: 400,
        m: 3,
        backgroundColor: "#c0bfb21a"
      }}
      id={props.categoryName}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={icon}>
            {props.name}
          </Avatar>
        }
        title={props.name}
      />

      <CardMedia component="img" height="194" image={image} alt={props.name} />
      <CardContent>
        {" "}
        <div className="price">Price: {props.price} LL</div>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
