import * as React from 'react';
import List from '@mui/material/List';
import Items from '../components/Item';
import Loading from '../../menu/components/Loading';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import ItemForm from '../components/ItemForm';
import useToken from '../../assest/UseToken';
export default function ItemsList() {
    const [items, setItems] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [addItem, setAddItem] = React.useState({ open: false, itemInfo: new FormData(), yesOrNo: false })
    const [categories, setCategories] = React.useState([])
    const token = useToken().authorisation?.token;

    async function getItems() {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/items`);
        let items = await response.json();
        setItems(items)
        setIsLoading(false)
    }
    async function getCategories() {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/categories`);
        let categories = await response.json();
        setCategories(categories.categories)
        setIsLoading(false)
    }
    React.useEffect(() => {
        getCategories()
        getItems();
    }, []);
    React.useEffect(() => {
        if (addItem.yesOrNo == true) {
            fetch(`${process.env.REACT_APP_URL}/api/items/addItem`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: addItem.itemInfo,
            })
                .then((response) => {
                    if (response.status) {
                        return response.json();
                    }
                    throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                    if (responseJson.hasOwnProperty('name')) {
                        getItems();

                    }
                    return responseJson;
                })
                .catch((error) => {
                    console.log(error);
                });
            addItem.yesOrNo = false
        }
    }, [addItem.yesOrNo]);


    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (

                <List sx={{ width: '98%', bgcolor: 'background.paper' }}>
                    <ItemForm open={addItem.open} setAddItem={setAddItem} itemInfo={addItem.itemInfo} addItem={addItem} yesOrNo={addItem.yesOrNo} categories={categories} dialogTitle={'Add Item'} dialogContentText={'To add item to this website, please enter a item name, description, price, category and upload image'} action={'Add'} />
                    <Fab size="medium" color="primary" aria-label="add" onClick={(e) => setAddItem({ ...addItem, open: true })}>
                        <AddIcon />
                    </Fab>
                    {items.map(
                        ({ _id, name, description, price, image, category_id }) => (
                            <Items
                                icon={category_id.icon}
                                image={image}
                                description={description}
                                name={name}
                                categoryName={category_id.name}
                                category_id={category_id._id}
                                price={price}
                                categories={categories}
                                key={_id}
                                setItems={setItems}
                                items={items}
                                id={_id}
                            />
                        )
                    )}
                </List>
            )}
        </div>
    );
}