import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import AlertDialog from './Dialog';
import AlertMes from '../../menu/components/Alert';
import useToken from '../../assest/UseToken';
import CategoryFormEdit from './CategoryFormEdit';
export default function Category(props) {
    const icon = `${process.env.REACT_APP_URL}/${props.icon}`
    const [dialog, setDialog] = React.useState({ open: false, text: '', yesOrNo: false })
    const [alert, setAlert] = React.useState({ message: '', severity: '', open: false });
    const token = useToken().authorisation?.token;
    const [editCategory, setEditCategory] = React.useState({ open: false, categoryInfo: new FormData(), yesOrNo: false })
    const fetchData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_URL}/api/categories/${props.id}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!response) {
            throw new Error("Data coud not be fetched!");
        } else {
            return response.json();
        }
    };
    React.useEffect(() => {
        if (dialog.yesOrNo === true) {
            fetchData()
                .then((res) => {
                    if (res.hasOwnProperty('message')) {
                        var categories = props.categories.filter(x => {
                            return x._id !== props.id;
                        })
                        props.setCategories(categories)
                        setAlert({ ...alert, message: res.message, open: true, severity: 'success' })
                    }
                })
                .catch((e) => {
                    console.log(e.message);
                });
        }
    }, [dialog.yesOrNo])
    React.useEffect(() => {
        if (editCategory.yesOrNo === true) {
            fetch(`${process.env.REACT_APP_URL}/api/categories/${props.id}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: editCategory.categoryInfo,
            })
                .then((response) => {
                    if (response.status) {
                        return response.json();
                    }
                    throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                    if (responseJson.hasOwnProperty('name')) {
                        const newCategory = props.categories.map(obj => {
                            if (obj._id === props.id) {
                                return { ...obj, ...responseJson };
                            }
                            return obj;
                        });
                        props.setCategories(newCategory)
                        setAlert({ ...alert, message: "Category updated!", open: true, severity: 'success' })
                    }
                    return responseJson;
                })
                .catch((error) => {
                    console.log(error);
                });
            editCategory.yesOrNo = false
        }
    }, [editCategory.yesOrNo])
    return (

        <div>
            <AlertDialog open={dialog.open} text={dialog.text} setOpen={setDialog} />
            <AlertMes open={alert.open} severity={alert.severity} message={alert.message} setOpen={setAlert} />
            <CategoryFormEdit open={editCategory.open} setEditCategory={setEditCategory} categoryData={props} categoryInfo={editCategory.categoryInfo} editCategory={editCategory} yesOrNo={editCategory.yesOrNo}  />
            <ListItem
                secondaryAction={
                    <div className='actions'>
                        <IconButton edge="end" aria-label="delete" onClick={(e) => { setDialog({ ...dialog, open: true, text: 'You are trying delete an Category! Are you sure?' }) }}>
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={(e) => setEditCategory({ ...editCategory, open: true })}>
                            <EditIcon sx={{ color: 'blue' }} />
                        </IconButton>
                    </div>
                }>
                <ListItemAvatar>
                    <Avatar src={icon}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.name} secondary={props.title} />
            </ListItem >
            <Divider variant="inset" component="li" />
        </div >
    );
}