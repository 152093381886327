import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    const handleCancel = () => {
        props.setOpen({ ...alert, open: false, text: props.text });
    };
    const handleYes = () => {
        props.setOpen({ ...alert, open: false, text: props.text, yesOrNo: true });
    };
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle  sx={{color:'red'}}  id="alert-dialog-title">
                    {"Alert!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText  sx={{color:'black'}}  id="alert-dialog-description">
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
