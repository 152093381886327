import * as React from 'react';
import ItemCard from '../components/ItemCard';
import Box from '@mui/material/Box';
import Loading from '../components/Loading';

export default function Items() {
    const [items, setItems] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    React.useEffect(() => {
        async function getData() {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/items`);
            let items = await response.json();
            setItems(items)
            setIsLoading(false)
        }
        getData();
    }, []);

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >

                        {items.map(
                            ({ _id, name, description, price, image, category_id }) => (
                                <ItemCard
                                    icon={category_id.icon}
                                    image={image}
                                    description={description}
                                    name={name}
                                    categoryName={category_id.name}
                                    price={price}
                                    key={_id}
                                />
                            )
                        )}
                    </Box>

                </>
            )}

        </div>
    )

}