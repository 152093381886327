import * as React from 'react';
import Box from '@mui/material/Box';
import CategoryCard from '../components/CategoryCard';
import Loading from '../components/Loading';

export default function Categories() {
    const [categories, setCategoris] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    React.useEffect(() => {
        async function getData() {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/categories`);
            let categories = await response.json();
            setCategoris(categories.categories)
            console.log(categories)
            setIsLoading(false)
        }
        getData();
    }, []);



    return (
        <div>
            {isLoading ? (
              <Loading/>
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >

                        {categories.map(
                            ({ _id, name, title, icon}) => (
                                <CategoryCard
                                    icon={icon}
                                    title={title}
                                    name={name}
                                    id={name}
                                    key={_id}
                                />
                            )
                        )}
                    </Box>

                </>
            )}

        </div>
    )

}